import './styles/App.scss';
import FilterBar from './components/FilterBar';
import PostingsIndex from './components/PostingsIndex';
import PostingsMap from './components/PostingsMap';
import { useSelector } from 'react-redux';

function App() {
  const showMap = useSelector(state => state.ui.map.showMap)

  return (
    <div className='App'>
      <header className='careers-header'>
        <div className='logo-container'>
          <a href='https://fpimgt.com/careers' target="_blank" rel='noreferrer'>
            <img className='header-logo' src='/images/HeaderLogo.png' alt='FPI Management Logo'/>
          </a>
        </div>
        <FilterBar />
      </header>
      <div className='content-container'>
        {/* Conditionally display the map based on the ui setting in the redux store (toggled by user) */}
        {showMap && <PostingsMap />}
        <PostingsIndex />
      </div>
    </div>

  );
}

export default App;
