import React, {useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCities } from '../store/cities';
import { fetchStates } from '../store/states';
import { fetchDepartments } from '../store/departments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faCheck, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import FilterDropdown from './FilterDropdown';
import { setActiveFilter } from '../store/activeFilters';
import { useDebounce } from '../utility';
import { useSearchParams } from 'react-router-dom';
import '../styles/FilterBar.scss';
import { setFrontendFilter } from '../store/ui';
import ToggleMapButton from './ToggleMapButton';

function FilterBar() {
  const dispatch = useDispatch();

  // Get references to dropdown options that are in redux state
  const departments = useSelector((state) => state.departments);
  const cities = useSelector((state) => state.cities);
  const states = useSelector((state) => state.states);

  // Create refs for each dropdown, allowing for invocation of child component functions
  const departmentRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams()

  // Store user input for job title
  // Input is the user direct input
  // Filter only updated after the user stops typing (debounced) and correlates with the value sent in the postings query
  const [jobTitleInput, setJobTitleInput] = useState(searchParams.get('q') || '')
  const [jobTitleFilter, setJobTitleFilter] = useState(searchParams.get('q') || '')

  // Filter for Schedule (typeOfEmployment from the API) such as 'Full-time' or 'Part-time'
  const scheduleTypeMenu = useRef(null);
  const scheduleType = useSelector(state => state.ui.frontendFilters.typeOfEmployment);
  const [scheduleTypeDropdownActive, setScheduleTypeDropdownActive] = useState(false);

  // Opens or closes the dropdown menu
  const toggleDropdown = e => {
    setScheduleTypeDropdownActive(!scheduleTypeDropdownActive);
  }

  // Closes a dropdown when the target of the click does not include the menu
  // In practice, allows a user to click outside of the menu to close it
  const closeDropdown = e => {
    if(scheduleTypeMenu.current && scheduleTypeDropdownActive && !scheduleTypeMenu.current.contains(e.target)) {
        setScheduleTypeDropdownActive(false);
    }
  }

  document.addEventListener('mousedown', closeDropdown)

  // When clicking an option for scheduleType, applies the selection and closes the menu
  const selectOption = option => e => {
    setScheduleTypeDropdownActive(false);
    dispatch(setFrontendFilter('typeOfEmployment', option));
  }

  // Reset active filters by invoking each child's reset function
  const resetFilters = (e) => {
    departmentRef.current.resetSelection();
    cityRef.current.resetSelection();
    stateRef.current.resetSelection();
    setJobTitleInput('');
    updateTitleFilter();
    dispatch(setFrontendFilter('typeOfEmployment', ''));
  }

  // Debouncing the update prevents refreshing the component/updating activeFilters and searchParams on each character change
  // The update is only performed when the user stops typing
  const updateTitleFilter = useDebounce(
    () => {
      setJobTitleFilter(jobTitleInput);
      setSearchParams((params) => {
        jobTitleInput === '' ? params.delete('q') : params.set('q', jobTitleInput);
        return params
      })
    }, 500
  );

  // When the filter for job title has updated, update the activeFilter use for the query
  // This is separate so that the activeFilter only updates after the debounced input update
  useEffect( () => {
    dispatch(setActiveFilter('q', jobTitleFilter))
  },
  [dispatch, jobTitleFilter]
  )

  // Fetches the cities, departments, and states when the component first loads
  // This is necessary so that the searchParams, activeFilters, and input fields can sync
  useEffect( () => {
    dispatch(fetchCities());
    dispatch(fetchDepartments());
    dispatch(fetchStates());
  },
  [dispatch]
  )

  return (
    <div className='filter-container'>
      <div className='filter-group'>
        <div className='filter-container-title'>
          <input type='text' name='jobTitle' className='job-title-filter-input' placeholder='Search by Job Title' onChange={(e) => {setJobTitleInput(e.target.value); updateTitleFilter();}} value={jobTitleInput}></input>
        </div>
        <FilterDropdown ref={departmentRef} nextTab={'city'} displayName={'Department'} filterName={'department'} optionsList={departments} fetchOptions={fetchDepartments} />
        <FilterDropdown ref={cityRef} nextTab={'region'} displayName={'City'} filterName={'city'} optionsList={cities} fetchOptions={fetchCities} />
        <FilterDropdown ref={stateRef} nextTab={'jobTitle'} displayName={'State'} filterName={'region'} optionsList={states} fetchOptions={fetchStates} />
      </div>
      <div className='filter-group'>
        <div ref={scheduleTypeMenu} className='filter-container-employment-type'>
          <button className='sort-dropdown-button' onClick={toggleDropdown}><span>{scheduleType ? scheduleType : 'Schedule Type'}</span><FontAwesomeIcon icon={faCaretDown} className='fa-caret'/></button>
          { scheduleTypeDropdownActive &&
            <div className='sort-dropdown-options-container'>
              <div className='sort-dropdown-option' onClick={selectOption('')}>
                <FontAwesomeIcon icon={faCheck} className={'fa-check ' + (scheduleType === '' ? 'present' : 'hidden')}/>
                <span> Either</span>
              </div>
              <div className='sort-dropdown-option' onClick={selectOption('Full-time')}>
                <FontAwesomeIcon icon={faCheck} className={'fa-check ' + (scheduleType === 'Full-time' ? 'present' : 'hidden')}/>
                <span> Full-time</span>
              </div>
              <div className='sort-dropdown-option' onClick={selectOption('Part-time')}>
                <FontAwesomeIcon icon={faCheck} className={'fa-check ' + (scheduleType === 'Part-time' ? 'present' : 'hidden')}/>
                <span> Part-time</span>
              </div>
            </div>
          }
        </div>
        <button className='filter-container-button' onClick={resetFilters}><FontAwesomeIcon icon={faArrowsRotate}/><span className='button-text'>Reset Filters</span></button>
        <ToggleMapButton />
      </div>
    </div>
  )
}

export default FilterBar;
