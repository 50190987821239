export const RECEIVE_POSTINGS = 'RECEIVE_POSTINGS';

export const receivePostings = (data) => {
  return {
    type: RECEIVE_POSTINGS,
    data
  }
}

export const fetchPostings = (filters) => async (dispatch) => {
  const filtersWithContent = Object.entries(filters).filter(([_filterName, query]) => (query?.length > 0 || typeof query === 'number'));
  const encodedFilters = filtersWithContent.map(([filterName, query]) => filterName.concat('=', encodeURIComponent(query)));
  let queryString = '';
  encodedFilters.forEach((component, idx) => {
      queryString = queryString.concat((idx === 0) ? '?' : '&', component)
  })
  const postingData = await fetch(`${process.env.REACT_APP_NODE_APP_BASE_URL}/search/jobs${queryString}`);
  if (!postingData.ok) return;
  const parsedData = await postingData.json();
  dispatch(receivePostings(parsedData))
}

export const postingReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_POSTINGS:
      return action.data.jobData;
    default:
      return state;
  }
}
