import React, { useEffect, useRef, useState } from 'react';
import PostingsIndexItem from './PostingsIndexItem';
import '../styles/PostingsIndex.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { selectPostings } from '../store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostings } from '../store/postings';
import { useDebounce } from '../utility';
import { setActiveFilter } from '../store/activeFilters';
import { useSearchParams } from 'react-router-dom';

function PostingsIndex() {
  const sortMenu = useRef(null);
  const [sortOrder, setSortOrder] = useState('Newest');
  const [sortDropdownActive, setSortDropdownActive] = useState(false);
  const activeFilters = useSelector(state => state.activeFilters)
  const frontendFilters = useSelector(state => state.ui.frontendFilters)
  const postings = useSelector(state => state.postings);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Debouncing the fetch prevents fetching on each character change
  // The fetch is only performed when the user stops typing
  const debouncedFetchPostings = useDebounce(() => {
    dispatch(fetchPostings(activeFilters))
  });

  // Postings are updated when activeFilters change (job title, department, city, state/region, etc.)
  useEffect(() => {
    debouncedFetchPostings();
  }, [dispatch, debouncedFetchPostings, activeFilters])

  // Opens or closes the dropdown menu
  const toggleDropdown = e => {
    setSortDropdownActive(!sortDropdownActive);
  }

  // Closes a dropdown when the target of the click does not include the menu
  // In practice, allows a user to click outside of the menu to close it
  const closeDropdown = e => {
    if(sortMenu.current && sortDropdownActive && !sortMenu.current.contains(e.target)) {
        setSortDropdownActive(false);
    }
  }

  document.addEventListener('mousedown', closeDropdown)

  // When clicking an option for sortOrder, applies the selection and closes the menu
  const selectOption = option => e => {
    setSortOrder(option);
    setSortDropdownActive(false);
  }

  // Changes the offset in the activeFilters as well as the searchParams
  // If the offset would go below 0, set the offset to 0 instead
  const changeOffset = offset => e => {
    offset = Math.max(offset, 0);
    setSearchParams((params) => {
      params.set('offset', offset);
      return params;
    })
    dispatch(setActiveFilter('offset', offset))
  }

  // On loading the component, get the offset from the searchParams and set the activeFilter
  // Essentially allows saving the offset to the URL and having it be respected on load
  useEffect(() => {
    dispatch(setActiveFilter('offset', Math.max(Number(searchParams.get('offset')), 0) || 0))
  }, [])

  return (
    <div className='postings-index'>
      <div className='postings-pagination'>
        <p>Found {postings.totalFound} opportunities</p>
        <div ref={sortMenu} className='sort-dropdown'>
          <button className='sort-dropdown-button' onClick={toggleDropdown}><span>By {sortOrder}</span><FontAwesomeIcon icon={faCaretDown} className='fa-caret'/></button>
          { sortDropdownActive &&
            <div className='sort-dropdown-options-container'>
              <div className='sort-dropdown-option' onClick={selectOption('Newest')}>
                <FontAwesomeIcon icon={faCheck} className={'fa-check ' + (sortOrder === 'Newest' ? 'present' : 'hidden')}/>
                <span> By Newest</span>
              </div>
              <div className='sort-dropdown-option' onClick={selectOption('Oldest')}>
                <FontAwesomeIcon icon={faCheck} className={'fa-check ' + (sortOrder === 'Oldest' ? 'present' : 'hidden')}/>
                <span> By Oldest</span>
              </div>
            </div>
          }
        </div>
      </div>
      { postings.totalFound > 0
        ?
        <>
          <ul className='postings-index-list'>
            {selectPostings(postings.content, sortOrder, frontendFilters).map(posting => <PostingsIndexItem key={posting.id} posting={posting}/>)}
          </ul>
          <div>
            <span>Showing records {postings.offset+1} through {(postings.offset + postings.limit) > postings.totalFound ? postings.totalFound : (postings.offset + postings.limit)} of {postings.totalFound}</span>
            <button onClick={changeOffset(postings.offset - postings.limit)} disabled={postings.offset <= 0}>Previous page</button>
            <button onClick={changeOffset(postings.offset + postings.limit)} disabled={(postings.offset + postings.limit) > postings.totalFound}>Next page</button>
          </div>
        </>
        :
          <span>Try expanding your search by using different filters in the search bar.</span>
      }
    </div>
  )
}

export default PostingsIndex;
