export const RECEIVE_CITIES = 'RECEIVE_CITIES';

export const receiveCities = (data) => {
  return {
    type: RECEIVE_CITIES,
    data
  }
}

export const fetchCities = () => async (dispatch) => {
  const cityData = await fetch(`${process.env.REACT_APP_NODE_APP_BASE_URL}/cache/cities`);
  const parsedData = await cityData.json();
  const citiesObject = {};
  // Create a consistant {id: ... label: ...} object format for all dropdowns
  parsedData.forEach(city => citiesObject[city] = {id: city, label: city});

  dispatch(receiveCities(citiesObject));
}

export const cityReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_CITIES:
      return {...state, ...action.data};
    default:
      return state;
  }
}
