import { useEffect, useMemo, useRef } from "react";

// Date formatter
// Default format of short month followed by nmeric day and year (Aug 23, 2023)
// Accepts customOptions to override any standard date formatting
export function parseDate(utcDateString, customOptions = {}) {
  const options = { month: 'short', day: 'numeric', year: 'numeric', ...customOptions}
  const date = new Date(utcDateString);
  return new Intl.DateTimeFormat('default', options).format(date);
}

// Basic debounce utility that takes in a callback that will be invoked after a given time, unless called again beforehand
// Used to ensure only one call is made in a given time period (delay a call until a user finishes typing rather than after each key, etc.)
export const debounce = (func, timer = 500) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), timer);
  }
}

// Allows for debouncing functionality within react components
// Memoization and refs are needed in order to properly clear timeouts across component rerendering
export const useDebounce = (callback, timer = 500) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, timer)
  }, [timer]);

  return debouncedCallback;
}

// Mapping of state abbreviations to full names
// Used to consolidate discrepancies in data returned from SmartRecruiters API
// Allows for consistant display of state names to the user
export const statesMapping = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "FL": "Florida",
  "GA": "Georgia",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "RI": "Rhode Island",
  "SC": "South Carolin",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
}
