import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setActiveFilter } from '../store/activeFilters';
import '../styles/FilterBarDropdown.scss'

const FilterDropdown = forwardRef(({displayName, filterName, nextTab, optionsList}, ref) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeFilters = useSelector(state => state.activeFilters)
  const dropdownRef = useRef(null);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [input, setInput] = useState('')
  const [selected, setSelected] = useState(searchParams.get(filterName) || '');

  // Filter helper function
  // Remove options from a list that do not include the passed filterQuery (case insensitive)
  const filterOptions = (optionsList, filterQuery) => Object.values(optionsList).filter(option => (option.label?.toLowerCase().indexOf(filterQuery?.toLowerCase()) !== -1));

  // When a valid option is selected, set the active filter so that relevant postings are fetched
  useEffect(
    () => {
      dispatch(setActiveFilter(filterName, selected))
    },
    [dispatch, filterName, selected]
  )

  // Close the dropdown when the user clicks anywhere outside
  const closeDropdown = e => {
    if(dropdownRef.current && dropdownActive && !dropdownRef.current.contains(e.target)) {
        setDropdownActive(false);
    }
  }

  document.addEventListener('mousedown', closeDropdown)

  // Sync input text to search query/active filter on initial load
  useEffect(
    () => {
      if (activeFilters[filterName] && Object.keys(optionsList)?.length>0){
        setInput(optionsList[activeFilters[filterName]]?.label || '')
      }
    },
    [activeFilters, filterName, optionsList]
  )

  // When an option is selected, set the selected value, sync the input text to the full value, set the query parameters, and close the dropdown
  const select = option => e => {
    setSelected(option.id);
    setInput(option.label);
    setSearchParams((params) => {
      option.id === '' ? params.delete(filterName) : params.set(filterName, option.id);
      return params;
    })
    setDropdownActive(false);
  }

  // Allow the parent component to reset selection, clearing input, selected option, and associated query param
  // Used for resetting multiple dropdowns from one button in the parent
  useImperativeHandle(ref, () => ({
    resetSelection() {
      setSelected('');
      setInput('');
      setSearchParams('');
    }
  }));

  // Event handler for tab/return user entry in the dropdown
  // On keypress, the first valid option in the list is selected and the next input is focused
  // If no valid options exist or if the input is empty, clear the selection before focusing the next input
  const handleReturnAndTab = (e) => {
    if (e.key === "Tab" || e.key === "Enter") {
      // Prevent the default tabbing to allow for custom selection logic
      e.preventDefault();

      // Get a reference to the current valid options based on what the user has input so far
      const filteredOptions = filterOptions(optionsList, input);

      // If there is user input and there are matches, select the first match
      // If there are no matches for the input or the input is empty, clear the selection
      if (input.length > 0 && filteredOptions.length > 0) {
        select(filteredOptions[0])(e);
      } else {
        select({id: '', label: ''})(e);
      }

      // Get next input element and set it as the focus
      const nextInput = document.getElementsByName(nextTab)[0];
      nextInput.focus();
    }
  }

  return (
    <div ref={dropdownRef} className={`${filterName}-filter-dropdown`}>
      <input
        type='text'
        name={filterName}
        className={`${filterName}-filter-dropdown-input`}
        placeholder={displayName}
        onFocus={() => setDropdownActive(true)}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => handleReturnAndTab(e)}
        value={input}>
      </input>
      { dropdownActive && (Object.keys(optionsList)?.length > 0) &&
        <ul className={`${filterName}-filter-dropdown-options-container`}>
          { (selected !== '') && <li onClick={select({id: '', label: ''})}>Clear Selection</li> }
          {
            filterOptions(optionsList, input).map(option => (<li key={option.id} onClick={select(option)}>{option.label}</li>))
          }
        </ul>
      }
    </div>
  );
});

export default FilterDropdown;
