export const RECEIVE_DEPARTMENTS = 'RECEIVE_DEPARTMENTS';

export const receiveDepartments = (data) => {
  return {
    type: RECEIVE_DEPARTMENTS,
    data
  }
}

export const fetchDepartments = () => async (dispatch) => {
  const departmentData = await fetch(`${process.env.REACT_APP_NODE_APP_BASE_URL}/cache/departments`);
  const parsedData = await departmentData.json();
  const departmentObject = {};
  // Create a consistant {id: ... label: ...} object format for all dropdowns
  parsedData.forEach(department => departmentObject[department.id] = department);
  dispatch(receiveDepartments(departmentObject));
}

export const departmentReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_DEPARTMENTS:
      return {...state, ...action.data};
    default:
      return state;
  }
}
