import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import { cityReducer } from './cities';
import { stateReducer } from './states';
import { departmentReducer } from './departments';
import { activeFilterReducer } from './activeFilters';
import { postingReducer } from './postings';
import { uiReducer } from './ui';

const rootReducer = combineReducers({
  cities: cityReducer,
  states: stateReducer,
  departments: departmentReducer,
  activeFilters: activeFilterReducer,
  postings: postingReducer,
  ui: uiReducer
});

let enhancer;

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(thunk);
} else {
  // Logs redux store changes to the browser, only in development
  const logger = require('redux-logger').default;
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
};

const configureStore = (preloadedState) => {
  return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
