export const SET_FRONTEND_FILTER = 'SET_FRONTEND_FILTER';
export const TOGGLE_MAP_DISPLAY = 'TOGGLE_MAP_DISPLAY';
export const UPDATE_MAP_BOUNDS = 'UPDATE_MAP_BOUNDS';

export const setFrontendFilter = (filterName, query) => {
  return {
    type: SET_FRONTEND_FILTER,
    filterName,
    query
  }
}

export const toggleMapDisplay = () => {
  return {
    type: TOGGLE_MAP_DISPLAY
  }
}

export const updateMapBounds = (bounds) => {
  return {
    type: UPDATE_MAP_BOUNDS,
    bounds
  }
}

export const uiReducer = (state = {}, action) => {
  let newMapState = {};
  switch (action.type) {
    case SET_FRONTEND_FILTER:
      return Object.assign({}, state, {frontendFilters: {[action.filterName]: action.query}});
    case TOGGLE_MAP_DISPLAY:
      newMapState =  Object.assign({}, state.map, {showMap: !state.map.showMap})
      return Object.assign({}, state, {map: newMapState});
    case UPDATE_MAP_BOUNDS:
      newMapState = Object.assign({}, state.map, {bounds: action.bounds})
      return Object.assign({}, state, {map: newMapState});
    default:
      return state;
  }
}
