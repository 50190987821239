// Selector to filter postings by the frontend filters
// For each frontend filter, only include in the result if the query matches
export function selectPostings(postings, sortOrder = 'Newest', frontendFilters = {}) {
  let filteredPostings = postings.filter(posting => {
    let passes = true;
    Object.entries(frontendFilters).forEach(([filter, query]) => {
      if ((query !== '') && (posting[filter].label !== query)) passes = false;
    })
    return passes;
  })
  return filteredPostings.sort((a, b) => {
    if (sortOrder === 'Oldest') {
      return a.releasedDate < b.releasedDate ? -1 : 1;
    } else {
      return a.releasedDate > b.releasedDate ? -1 : 1;
    }
  });
}
