export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER';

export const setActiveFilter = (filterName, query) => {
  return {
    type: SET_ACTIVE_FILTER,
    filterName,
    query
  }
}

export const activeFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_FILTER:
      // Resets offset to 0 if any filter other than offset are changing
      // Prevents issue of a user being on a later page (i.e., postings 501-600), applying a filter resulting in fewer results and having nothing displayed
      return {...state, 'offset': 0, [action.filterName]: action.query};
    default:
      return state;
  }
}
