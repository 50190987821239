import { statesMapping } from "../utility";

export const RECEIVE_STATES = 'RECEIVE_STATES';

export const receiveStates = (data) => {
  return {
    type: RECEIVE_STATES,
    data
  }
}

export const fetchStates = () => async (dispatch) => {
  const stateData = await fetch(`${process.env.REACT_APP_NODE_APP_BASE_URL}/cache/states`);
  const parsedData = await stateData.json();
  const statesObject = {};
  // Create a consistant {id: ... label: ...} object format for all dropdowns
  parsedData.forEach(state => {
    const fullStateName = statesMapping[state] || state
    statesObject[fullStateName] = {id: fullStateName, label: fullStateName}
  });

  dispatch(receiveStates(statesObject));
}

export const stateReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_STATES:
      return {...state, ...action.data};
    default:
      return state;
  }
}
