export const defaultStore = {
  cities: {},
  states: {},
  departments: {},
  activeFilters: {
    city: '',
    region: '',
    department: '',
    q: '',
    offset: 0,
    limit: 100
  },
  ui: {
    map: {
      showMap: true,
      bounds: '',
      center: { lat: 38.64314, lng: -121.18397 }
    },
    // Frontend Filters are those that cannot be queried for with the SmartRecruiters API
    // They are tracked separately and postings are filtered within the React application
    // Allows the user to filter by more values and can be expanded upon for any data within a posting
    frontendFilters: {
      typeOfEmployment: ''
    }
  },
  postings: {
    totalFound: 0,
    limit: 100,
    offset: 0,
    content: []
  }
};
