import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toggleMapDisplay } from '../store/ui';

// Button to Show/Hide Map based on current state of ui.map.showMap in Redux
// Can be placed anywhere in the application
function ToggleMapButton() {
  const showMap = useSelector((state) => state.ui.map.showMap);
  const dispatch = useDispatch();

  return (
    <button className='filter-container-button toggle-map' onClick={() => dispatch(toggleMapDisplay())}>
      <span className='button-text toggle-map'>{showMap ? "Hide Map": "Show Map"}</span>
    </button>
  )
}

export default ToggleMapButton;
