import React, { useEffect, useMemo, useRef } from 'react';
import '../styles/PostingsMap.scss'
import { GoogleMap, Marker, useLoadScript} from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import BoundsChangeComponent from './BoundsChangeComponent';

function PostingsMap() {
  const postings = useSelector(state => state.postings.content)
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API
  })

  // Center on 800 Iron Point office
  const center = useMemo(() => ({lat: 38.64314, lng: -121.18397}), []);

  // Create a reference for markerBounds and initialize it to a LatLngBounds object when the map loads
  let markerBounds = useRef();
  useEffect(() => {
    if (isLoaded) {
      markerBounds.current = new window.google.maps.LatLngBounds();
    }
  }, [isLoaded, postings])

  return (
    <>
    { !isLoaded ?
      <div className='map-container-loading'>
        <span>Loading Map</span>
      </div>
    :
    <GoogleMap mapContainerClassName='map-container' center={center} zoom={9}>
      {/* For each posting, if lat/long data exists, create a new Marker added to the map and track the positions in markerBounds */}
      {/* markerBounds allows for automatic map panning when the markers on the map change */}
      { postings.reduce((postings, posting) => {
        if (posting.location.latitude && posting.location.longitude) {
          const position = new window.google.maps.LatLng(Number(posting.location.latitude), Number(posting.location.longitude))
          postings.push(
            <Marker
              key={posting.id}
              position={position}
              title={posting.name}
              icon={'/images/MapPin.svg'}
              onClick={()=>{
                // Event listener that scrolls to the related PostingIndexItem when a Marker is clicked
                const selectedPosting = document.getElementById(`posting-id-${posting.id}`);
                selectedPosting.scrollIntoView({behavior: 'smooth'});
                // TODO?: On click, flash component to indicate it was the selected marker
              }}
            />)
            markerBounds.current?.extend(position)
        }
        return postings;
      }, [])}
      {/* The map context is only exposed to child components, so BoundsChangeComponent is used to hook into this context to adjust bounds/zoom when markers change */}
      <BoundsChangeComponent markerBounds={markerBounds.current} center={center}/>
    </GoogleMap>
    }
    </>
  )
}

export default PostingsMap;
