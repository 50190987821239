import React from 'react';
import { parseDate } from '../utility';
import '../styles/PostingsIndexItem.scss'

function PostingsIndexItem({posting}) {
  // For Onsite positions, get the Property Name custom field
  // For all other positions, use the company name ("FPI Management, Inc.")
  const propertyName = (posting.department.label === 'Onsite') ? posting.customField.filter(customField => customField.fieldLabel === "Property Name")[0]?.valueLabel : posting.company.name;
  return (
    <li id={`posting-id-${posting.id}`} className='postings-index-item'>
      <div className='postings-index-item-link-container'>
        {/* Links to the application page for this particular job posting on the SmartRecruiters website */}
        <a href={process.env.REACT_APP_SMART_RECRUITERS_APPLICATION_BASE_URL.concat(posting.id)} target='_blank' rel='noreferrer' className='postings-index-item-link'>{posting.name}</a>
        <p className='postings-index-item-posting-date'>{parseDate(posting.releasedDate)}</p>
      </div>
      <p className='postings-index-item-category'><strong>Job Category:</strong> {posting.department.label}</p>
      <p className='postings-index-item-schedule'><strong>Schedule:</strong> {posting.typeOfEmployment.label}</p>
      <div className='postings-index-item-address-container'>
        {/* If a property name does not exist for any reason, omit the line */}
        { propertyName && <p className='postings-index-item-company-line'><strong>Property:</strong> {propertyName}</p>}
        <p className='postings-index-item-address-line'>{posting.location.address}</p>
        <p className='postings-index-item-city-line'>{posting.location.city}, {posting.location.region} {posting.location.postalCode}, {posting.location.country.toUpperCase()}</p>
      </div>
    </li>
  )
}

export default PostingsIndexItem;
