import { useEffect } from 'react'
import { useGoogleMap } from '@react-google-maps/api'

function BoundsChangeComponent({markerBounds, center}) {
  // Gets parent map context (must be a child component of the map)
  const map = useGoogleMap();

  useEffect(() => {
    // If markers have been added to the map, get the center point and fit all to screen
    if (markerBounds) {
      map.setCenter(markerBounds.getCenter());
      map.fitBounds(markerBounds);

      // Prevent extreme close-up zooms, particularly when a small number of close markers are returned
      if (map.getZoom() > 16) map.setZoom(16);

    // If no markers exist, center on the default
    } else {
      map.setCenter(center);
    }
  }, [map, markerBounds, center])

  // Component does not add anything to the map, just adjusts its bounds behavior
  return null
}

export default BoundsChangeComponent;
